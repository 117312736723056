import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequestCountService {
  private requestCountSubject = new BehaviorSubject<number>(0);
  requestCount$ = this.requestCountSubject.asObservable();

  setRequestCount(count: number) {
    this.requestCountSubject.next(count);
  }

  incrementRequestCount() {
    this.requestCountSubject.next(this.requestCountSubject.value + 1);
  }
}
