import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import * as _ from "lodash";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class ServiceGateway {
  constructor(private http: HttpClient) {}
  // #docregion get the http request data
  public getwithouttoken(url: string): Observable<any> {
    return this.http
      .get(url, httpOptions)
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError<any>("Exception occured")));
  }

  public postwithouttoken(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap((response: any) => console.log("post successfully")),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  public get2(url: string, token: string): Observable<any> {
    httpOptions.headers.append("Authorization", token);
    return this.http
      .get(url, httpOptions)
      .pipe(map(this.extractData))
      .pipe(catchError(this.handleError<any>("Exception occured")));
  }

  // #docregion get the http request data
  public get(url: string): Observable<any> {
    return this.http.get(url, this.getRequestOptions("")).pipe(
      tap((response) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  // #docregion get the http request data
  public getWithHeaders(url: string, headers: any): Observable<any> {
    return this.http.get(url, headers).pipe(
      tap((response) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  // #docregion post the http data
  public post(url: string, contenttype: string, data: any): Observable<any> {
    return this.http
      .post<any>(url, data, this.getRequestOptions(contenttype))
      .pipe(
        tap((response: any) => response),
        catchError(this.handleError<any>("Exception occured"))
      );
  }

  public post2(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data, this.getTokenHeader()).pipe(
      tap((response: any) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  public post3(url: string, contenttype: string, data: any): Observable<any> {
    return this.http
      .post<any>(url, data, this.getTokenHeader(contenttype))
      .pipe(
        tap((response: any) => response),
        catchError(this.handleError<any>("Exception occured"))
      );
  }

  public postByParam(url: string): Observable<any> {
    return this.http.post(url, this.getRequestOptions("")).pipe(
      tap((response) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }
  public put(url: string, contenttype: string, data: any): Observable<any> {
    return this.http
      .put<any>(url, data, this.getRequestOptions(contenttype))
      .pipe(
        tap((response: any) => response),
        catchError(this.handleError<any>("Exception occured"))
      );
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url, this.getRequestOptions("")).pipe(
      tap((response) => response),
      catchError(this.handleError<any>("Exception occured"))
    );
  }

  private extractData(res: Response) {
    let data = res;
    return data || new Array<any>();
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  private getRequestOptions(contentType: string): any {
    let authResponse: any = this.getAuthResponse();
    var _headers = {};
    if (
      contentType === "" ||
      contentType == null ||
      contentType === null ||
      contentType === "undefined"
    ) {
      _headers = _.extend(_headers, { "Content-Type": "application/json" });
    } else {
      _headers = _.extend(_headers, { "Content-Type": contentType });
    }

    let httpOption = {
      headers: new HttpHeaders(_headers),
    };

    return httpOption;
  }

  private getTokenHeader(authRes: any = null): any {
    let authResponse: any = authRes ? authRes : this.getAuthResponse();
    var _headers = {};
    if (
      authResponse == null ||
      authResponse === null ||
      typeof authResponse === "undefined"
    ) {
      //  console.error("Cannot append Authorization header, authResponse is null or undefined");
    } else {
      _headers = _.extend(_headers, {
        Authorization: "Bearer" + " " + authResponse,
      });
    }
    let httpOption = {
      headers: new HttpHeaders(_headers),
    };
    return httpOption;
  }

  private getAuthResponse(): any {
    return localStorage.getItem("access_token");
  }
}
