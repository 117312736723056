import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "src/app/shared/layout/layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { SuccessPageComponent } from "./shared/success-page/success-page.component";
import { FeeddbackComponent } from "./features/feeddback/feeddback.component";
import { ForgotPasswordComponent } from "./features/login/forgot-password/forgot-password.component";
import { CreatePassowrdComponent } from "./features/login/create-passowrd/create-passowrd.component";
import { AppLayoutComponent } from "./shared/app-layout/app-layout.component";

const appRoutes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("src/app/features/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "freeTrial",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/features/free-trial/free-trial.module").then(
            (m) => m.FreeTrialModule
          ),
      },
    ],
  },
  {
    path: "docservice",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/features/docservice/docservice.module").then(
            (m) => m.DocserviceModule
          ),
      },
    ],
  },
  {
    path: "client",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/features/client/client.module").then(
            (m) => m.ClientModule
          ),
      },
    ],
  },
  {
    path: "admin",
    component: AppLayoutComponent,
    children: [
      {
        path: "docservice",
        loadChildren: () =>
          import("src/app/features/docservice/docservice.module").then(
            (m) => m.DocserviceModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("src/app/features/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "docservice",
        loadChildren: () =>
          import(
            "src/app/features/docset-template/docset-template.module"
          ).then((m) => m.DocsetTemplateModule),
      },
    ],
  },

  {
    path: "error",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("src/app/shared/errors/errors.module").then(
            (m) => m.ErrorsModule
          ),
      },
    ],
  },
  {
    path: "success",
    component: SuccessPageComponent,
  },
  {
    path: "feedback",
    component: FeeddbackComponent,
  },
  {
    path: "forgotpassword",
    component: ForgotPasswordComponent,
  },
  {
    path: "resetpassword",
    component: CreatePassowrdComponent,
  },
  { path: "**", redirectTo: "admin/docservice/docrequest-list" }, //------------For JWT Login--------
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
