import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URLS, CONFIG } from "src/app/config/config";
import { toQueryString } from "../helpers/utility";
import { ServiceGateway } from "./servicegatewayNew.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionsService {
  private subscriptionCode: string | null = null;
  private subscriptionName: string | null = null;
  constructor(private serviceGateWay: ServiceGateway) {}
  setSubscription(plan: {
    subscription_code: string;
    subscription_name: string;
  }) {
    this.subscriptionCode = plan.subscription_code;
    this.subscriptionName = plan.subscription_name;
  }

  getSubscriptionCode(): string | null {
    return this.subscriptionCode;
  }

  getSubscriptionName(): string | null {
    return this.subscriptionName;
  }
  public getAllSubscriptionPlans(): Observable<any> {
    return this.serviceGateWay.get(
      API_URLS.Generic_api_url + API_URLS.getAllSubscriptionPlans
    );
  }

  public getSubscriptionPlanDetailsById(data: any): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.getSubscriptionPlanDetailsById,
      CONFIG.ContentType,
      data
    );
  }

  public addTenantSubscriptions(data: any): Observable<any> {
    return this.serviceGateWay.post(
      API_URLS.Generic_api_url + API_URLS.addTenantSubscriptions,
      CONFIG.ContentType,
      data
    );
  }
  public getStorageInfo(): Observable<any> {
    return this.serviceGateWay.get(
      API_URLS.Generic_api_url + API_URLS.getStorageInfo
    );
  }
  public getTenantSubscriptionPlan(): Observable<any> {
    return this.serviceGateWay.get(
      API_URLS.Generic_api_url + API_URLS.getTenantSubscriptionPlan
    );
  }
}
