<header class="top-menu">
  <div class="grid-container">
    <div class="top-header">
      <div class="top-header-brand">
        <img alt="logo" src="{{ logo }}" (click)="onLogoClick()" id="logo" />
        <img alt="logo" src="{{ mobileLogo }}" (click)="onLogoClick()" id="mobilelogo" />
        <ul class="menu-ul-list">
          <li class="menu-list" *ngFor="let navItem of navBarItems;trackBy: trackByFn">
            <a class="menu-item" [routerLinkActive]="'usa-current'" [routerLink]="navItem.routerLink">{{
              navItem.label}}
            </a>
          </li>
        </ul>
      </div>
      <div class="display-in-desktop" *ngIf="this.subscriptionDetails?.userStorageSize" style="margin-left: auto;">
        <div class="grid-row">
          <div class="grid-row usage-container">
            <div class="p-10">
              <div class="grid-row">
                <div [ngStyle]="{'background': gradient}" class="dataUsage-container">
                </div>
              </div>
              <span class="f-10">{{this.subscriptionDetails.storageUsedByUser}} of
                {{this.subscriptionDetails.userStorageSize}} used</span>
            </div>
            <div class="verticle-line"></div>
            <div class="grid-row display-flex flex-row flex-align-center p-10">
              <div class="m-r-8">
                <span class="blue-text">{{requestCount}}</span>
                <span class="f-12">/{{this.subscriptionDetails.no_of_request_provided?
                  this.subscriptionDetails.no_of_request_provided:'unlimited'}}</span>
              </div>
              <div>
                <p class="f-10 m-0">
                  Requests
                </p>
                <p class="f-10 m-0">
                  Pending
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="no-days" *ngIf="isFreeTrial">
            <div class="no-days-text" *ngIf="daysLeft>0">{{daysLeft}}</div>
            <div class="days-left" *ngIf="daysLeft>0">Days Left</div>
          </div> -->
        </div>

      </div>
      <!-- <div class="no-days display-in-mobile" *ngIf="isFreeTrial">
        <div class="no-days-text" *ngIf="daysLeft>0">{{daysLeft}}</div>
        <div class="days-left" *ngIf="daysLeft>0">Days Left</div>
      </div> -->



      <div class="dropdown">
        <div class="dropdown1">
          <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            cursor: pointer;
          ">
            <div class="dropbtn">
              <div class="user-info">
                <div class="p-avtar">
                  <span class="p-avatar-text ng-star-inserted">{{
                    userLogo | uppercase
                    }}</span>
                </div>
              </div>
            </div>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
          <div class="dropdown-content">
            <ul>
              <li style="font-weight: 600; font-size: 18px">
                <div>{{ fullName }},</div>
                <div style="font-weight: 400; font-size: 14px">{{ roleName }} </div>
              </li>
              <li style="font-weight: 400; font-size: 14px">
                Timezone: {{ timezone }}
              </li>
              <li style="font-weight: 400; font-size: 14px">
                Organization: {{ tenantName }}
              </li>
              <!-- <a (click)="goCMS()" *ngIf="isAdmin"
                style="font-size: 16px; font-weight: 500; cursor: pointer;color:#263873">
                View Administrator
              </a> -->
              <a [routerLink]="['/admin/profile']" style="font-size: 16px; font-weight: 500;color:#263873;">
                View Account
              </a>
              <button class="primary-button" (click)="logout()" style="width: 116px; font-size: 16px">
                Log Out
              </button>
            </ul>
          </div>
        </div>
      </div>

      <!-- Mobile SideBar -->
      <div (click)="onClick()" class="btn hemburger-icon">
        <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'topX' : 'hamburguer'"></div>
        <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'hide' : 'hamburguer'"></div>
        <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'bottomX' : 'hamburguer'"></div>
      </div>
      <div class="sidebar-main" [ngClass]="isHamburguer === true ? 'sidebar-main-open' : 'hidden'">
        <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            cursor: pointer;
          ">
          <div class="sidebar-content">
            <div class="user-avtar-info">
              <div class="user-info">
                <div class="p-avtar">
                  <span class="p-avatar-text ng-star-inserted">{{
                    userLogo | uppercase
                    }}</span>
                </div>
              </div>
              <div class="user-name-role">
                <div style="font-weight: bold; font-weight: 600; font-size: 18px">
                  {{ fullName }}
                </div>
                <div style="font-weight: bold; font-weight: 600; font-size: 14px">
                  {{ roleName }}
                </div>
              </div>
            </div>
            <!-- <a (click)="goCMS()" *ngIf="isAdmin" style="font-size: 16px; font-weight: 500; cursor: pointer;">
              View Administrator
            </a> -->
            <a [routerLink]="['/admin/profile']" style="font-size: 16px; font-weight: 500">
              View Account
            </a>
            <div class="grid-row usage-container" *ngIf="this.subscriptionDetails?.userStorageSize">
              <div class="p-10 m-auto">
                <div class="grid-row">
                  <div [ngStyle]="{'background': gradient}" class="dataUsage-container">
                  </div>
                </div>
                <span class="f-10">{{this.subscriptionDetails.storageUsedByUser}} of
                  {{this.subscriptionDetails.userStorageSize}} used</span>
              </div>
              <hr style="width: 100%;">
              <div class="grid-row display-flex flex-row flex-align-center p-10 m-auto">
                <div class="m-r-8">
                  <span class="blue-text">{{this.subscriptionDetails.no_of_request_created}}</span>
                  <span class="f-12">/{{this.subscriptionDetails.no_of_request_provided?
                    this.subscriptionDetails.no_of_request_provided:'unlimited'}}</span>
                </div>
                <div>
                  <p class="f-10 m-0">
                    Requests Pending
                  </p>
                </div>
              </div>
            </div>
            <button class="primary-button" (click)="logout()" style="width: 116px; font-size: 16px">
              Log Out
            </button>

            <hr class="underline" />
            <!-- menu items -->
            <ul class="sidebar-menu-ul-list">
              <li class="menu-list" *ngFor="let navItem of navBarItems">
                <a class="menu-item" [routerLinkActive]="'usa-current'" [routerLink]="navItem.routerLink"
                  (click)="isHamburguer = !isHamburguer">{{ navItem.label }}</a>
              </li>
            </ul>

          </div>
        </div>
      </div>

    </div>
  </div>
</header>
<div class="expired-content"
  *ngIf="(daysLeft<=10 && daysLeft>=0) && !(currentRoute.includes('profile')) && subscriptionBanner">
  <div class="expired-card">
    <div class="expired-text-content"><img src="../../../../assets/images/info-circle.svg">&nbsp;<span
        class="expired-info-text">
        <span *ngIf="daysLeft >0">Your subscription plan will expire in {{daysLeft}} days</span>
        <span *ngIf="daysLeft ==0">Your subscription plan has expired</span>
      </span>
      <a class="expired-link-text" (click)="navigateToSuubscriptionPlans()" target="_blank">Subscribe</a>
    </div>
  </div>
  <img src="../../../../assets/images/fi-rr-cross-small.svg" class="ps-absolute cursor-pointer"
    style="right: 20px; top: 15px;" (click)="closeBanner()">
</div>


<div *ngIf="isHamburguer === true" class="p-component-overlay" (click)="isHamburguer = !isHamburguer"></div>