import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class UserInfoService {
  constructor() {}

  isForcedPassword(): boolean {
    const helper = new JwtHelperService();
    const data = helper.decodeToken(localStorage.getItem("access_token"));
    if (data.forcedPassword === "true") {
      return true;
    }
    return false;
  }

  getValueFromJwtToken(key: string): string {
    const helper = new JwtHelperService();
    const data = helper.decodeToken(localStorage.getItem("access_token"));
    return data[key];
  }

  getTokenData(key: string): string {
    const helper = new JwtHelperService();
    const data = JSON.parse(localStorage.getItem("auth_info"));
    return data?.[key];
  }
}
