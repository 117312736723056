export enum ToastMessage {
    Success = 'Request successful.',
    Failure = 'Unable to process the request.',
    Exception = 'Error occured.',
    UnAuthorized = 'Request denied.',
    ValidationError = 'Request responded with validation error(s).',
    TokenExpired = 'Token Expired.',
    DataNotFound = 'Data Not Found.',
    DataAlreadyExists = 'Data Already Exists.',
    InvalidFileFormat = 'Invalid File Format.',
    NoFileUploaded = 'No File Uploaded',
    NoRecords = 'No records found.',
    DataExpired = 'Data Expired',
    PartialSuccess = 'Partially success, few items failed.',
    CompletedSuccessfully = 'Completed Successfully.',
    NotAcceptable = 'Current and New Password cannot be same.'
}

export enum ToastCostomMessage {
    Added = "Added Succesfully!.",
    Updated = "Updated Successfully!.",
    Error = "Error occured, Please try Again!",
    FormRequired = "Please Fill All Required Fields.",

    PasswordUpdate = "Password Updated Successfully!",
    PasswordMatch = "Password Should Match!",

    NoteAdded = "The Notes Added Successfully",
    NoteRequired = "Please add Notes",

    Delete = "Deleted successfully.",
    AllFileRequire = "All fields are required",
    Submitted = "Submitted successfully.",
    RecordSaved = "The record has been saved successfully.",
    MaxFile = "Max 10 files are allowed to upload.",
    FileDelete = "The file has been deleted successfully.",

    EmailSent = "Email has been Sent",
    InvalidEmail = "You have entered an invalid email address!",

    RequestCreated = "Request created successfully",
    RequestProcessed = "Request processed successfully",
    NotificationSent = "Notification has been sent.",
    FileSizeExceed = "File size exceeded the maximum allowed limit(20 MB)",
    FileTypeRestrict="Files Restricted",
    HtmlFileTypeRestrict="Only html files are allowed!!",
    FileTypeSelect="Please Choose File(s)",
    FileDownloadSuccess = "File Downloaded Successfully",
    SubscriptionExpiry = 'Your subscription plan has expired'
}

export enum Docstatus {
    Pending = "Pending",
    Completed = "Completed",
    AdditionalInfoRequired = "Additional Info Required",
    Uploaded = "Uploaded"
}

export enum DocstatusMessage {
    ToPending = "Status Changed to Pending Successfully",
    ToCompleted = "Status Changed to Completed Successfully",
    ToAdditionalInfoRequired = "Status Changed to Additional Info required Successfully",
    ToUploaded = "Status has been changed to uploaded Successfully"
}
export enum AdditionalInfoMessage {
    Note = "Please check notes for required additional",
   
}